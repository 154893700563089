/* You can add global styles to this file, and also import other style files */


.bg-thead {
  --bs-bg-opacity: 1;
  background-color: #fdf5e7;
}

#bg-blur {
  backdrop-filter: blur(70px);
}

.shadow-light {
  box-shadow: var(--bs-box-shadow) !important;
  --bs-box-shadow: 0 0.5rem 1rem rgba(101, 73, 38, 0.07) !important;
}

@media (min-width: 700px) {
  .modal-dialog {
    max-width: var(--bs-modal-width);
    --bs-modal-width: 700px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (max-width: 700px) {
  .modal-dialog {
    max-width: var(--bs-modal-width);
    --bs-modal-width: 500px;
    transition: all 0.3s ease-in-out;
    font-size: 0.8rem;
    margin-right: auto;
    margin-left: auto;
  }
  .icon-big {
    font-size: 0.8rem;
  }
}


/* Styles for small screens (max-width: 390px) */
@media (max-width: 390px) {
  aside {
    display: none;
  }

  /* TODO: Sidebar an Mobilgeräte anpassen */

  footer {
    display: none; /* Optional: Hide the footer content on small screens */
  }
}


.round-border {
  border-radius: 25px;
}

.icon-big {
  font-size: 1.25rem;
}

.left-round-border {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.right-round-border {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.table-hover {
  > tbody > tr:hover > * {
    --bs-table-hover-bg: #fdf5e7;
    --bs-table-hover-color: #000000;
    border-radius: initial;
  }
}

.table {
  vertical-align: middle;
}

body {
  //background-color: whitesmoke;

  align-content: stretch;

  @media (min-width: 992px) {
    #layoutSidenav .sb-sticky {
      transform: translateX(0);
    }
  }

  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
    min-height: 62px;
  }

  .navbar-expand-lg .navbar-toggler {
    display: block;
  }

  .d-flex {
    display: flex !important;
  }

  /* --- header --- */

  .navbar, .sb-topnav {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 50;
    box-shadow: 0 0.5rem 1rem rgba(150, 138, 111, 0.09);
  }

  /* --- Sidebar --- */

  #layoutSidenav {
    padding-top: 60px;
    display: flex;
    min-height: 100vh;
    align-content: stretch;
    align-items: stretch;
  }

  #layoutSidenav .layoutSidenav_content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    background-color: #f2f0f0; // kühl --> fdfcfc FFF6EC
  }

  .sb-sticky {
    position: relative;
    display: flex;
    left: 0;
    z-index: 5;
  }

  /* --- Sidebar Menu (Inhalt) --- */

  .sb-sidenav-menu {
    position: fixed;
    flex-grow: 1; // damit der Inhalt nicht über den Footer hinausgeht
    overflow-y: auto;
  }

  #list-group .sb-sidenav-menu-heading {
    padding: 1.75rem 1rem 0.75rem;
    font-size: 0.75rem;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
  }

  #list-group {
    padding: 0;
  }

  .list-group-item {
    display: flex;
    align-items: center;
    padding: 1rem 1.2rem;
  }

  // ****** Sidebar collapsed ******

  #sidebar-wrapper.collapsed {
    margin-left: -160px;
  }

  #sidebar-wrapper .sidebar-heading {
    display: block; /* Verhindert, dass der Text beim Verstecken der Sidebar übereinander geschoben wird */
    padding: 25px; /* Innenabstand für den Kopf der Sidebar */
  }

  // ****** ******

  .visible {
    animation: fadeIn 0.2s ease-in-out;
  }

  .hidden {
    animation: fadeOut 0.2s ease-in-out;
  }
}

main {

  .card-top {
    padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
    margin-bottom: 0;
    color: var(--bs-card-cap-color);
    background-color: var(--bs-card-cap-bg);
  }

  .mr-2 {
    margin-right: 0.5rem !important;
  }

  .ml-2 {
    margin-left: 0.5rem !important;
  }

  .mr-3 {
    margin-right: 1rem !important;
  }

  .bg-light-warm {
    background-color: #fdfcfc;
  }


}

